import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEOnoindex } from "src/components/seo-noindex"
import { Mobile } from "src/components/device"
import { Carousel } from "src/components/carousel"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"
import { VideoPlayer } from "src/components/video-player"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  Cell,
  Measure,
  HorizontalRuler,
  Headline,
  Paragraph,
  ParagraphTall,
  HeadlineHero,
  CellVerticalOffset,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      connecting_title: string
      connecting_copy: string
      data_capture_copy: string
      data_capture_2_copy: string
      data_capture_3_copy: string
      share_memories_title: string
      share_memories_copy: string
      feel_closer_title: string
      feel_closer_copy: string
      create_wherever_title: string
      create_wherever_copy: string
      expertise: string
    }
    images: {
      hero_video_static: any
      data_composition_static: any
      data_flow_static: any
      grid_1_model_static: any
      grid_1_scenario_generation_static: any
      grid_1_capture_journey_static: any
      fabulous_footage_static: any
      magic_moments_static: any
      ingenious_ideation_static: any
    }
    videos: {
      hero_video: any
      data_composition: any
      grid_1_model: any
      grid_1_scenario_generation: any
      fabulous_footage: any
      magic_moments: any
      ingenious_ideation: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEOnoindex
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.hero_video}
            image={images.hero_video_static}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.connecting_title}</Headline>
          <Paragraph>{copy.connecting_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.data_composition}
            image={images.data_composition_static}
          />
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.data_capture_copy}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container>
        <Block>
          <ImgFluid image={images.data_flow_static} />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.data_capture_2_copy}</Paragraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }} >
            <Col>
              <VideoLoopResponsive
                ratio="$1/1"
                video={videos.grid_1_model}
                image={images.grid_1_model_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                  ratio="$1/1"
                  video={videos.grid_1_scenario_generation}
                  image={images.grid_1_scenario_generation_static}
              />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }} >
              <ImgFluid image={images.grid_1_capture_journey_static} />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.data_capture_3_copy}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.share_memories_title}</Headline>
          <Paragraph>{copy.share_memories_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.fabulous_footage}
            image={images.fabulous_footage_static}
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <HeadlineAndParagraph>
              <Headline>{copy.feel_closer_title}</Headline>
              <Paragraph>{copy.feel_closer_copy}</Paragraph>
            </HeadlineAndParagraph>
          </Col>
        </Grid>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.magic_moments}
            image={images.magic_moments_static}
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.create_wherever_title}</Headline>
          <Paragraph>{copy.create_wherever_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.ingenious_ideation}
            image={images.ingenious_ideation_static}
          />
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "google-superformat" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        connecting_title
        connecting_copy
        data_capture_copy
        data_capture_2_copy
        data_capture_3_copy
        share_memories_title
        share_memories_copy
        feel_closer_title
        feel_closer_copy
        create_wherever_title
        create_wherever_copy
        expertise
      }
      images {
        hero_video_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        data_composition_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        data_flow_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_model_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_scenario_generation_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_capture_journey_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        fabulous_footage_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        magic_moments_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        ingenious_ideation_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        
      }
      videos {
        hero_video {
          src {
            src_large
            src_medium
            src_small
          }
        }
        data_composition {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_model {
          src
        }
        grid_1_scenario_generation {
          src
        }
        fabulous_footage {
          src {
            src_large
            src_medium
            src_small
          }
        }
        magic_moments {
          src {
            src_large
            src_medium
            src_small
          }
        }
        ingenious_ideation {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`


